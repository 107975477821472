import React, { lazy } from 'react';
import { useLocation, Navigate, useRoutes } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';
import { MainLayout } from 'components';
import Login from 'features/login';
import Constants from 'Constants';

const Home = lazy(() => import('features/home'));
const OurExercises = lazy(() => import('features/our-exercises'));
const OurMotionTracking = lazy(() => import('features/our-motion-tracking'));
const SampleWorkouts = lazy(() => import ('features/sample-workouts'));
const RangeOfMotion = lazy(() => import ('features/range-of-motion'));
const DemoExperience = lazy(() => import ('features/demo-experience'));

function RequireAuth({ children }: { children: JSX.Element }) {
  let location = useLocation();

  const isAuthenticated = (window as any).sessionStorage.kemtai === Constants.code;

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

const routes: RouteObject[] = [
  {
    path: "*",
    element: <RequireAuth>
              <Home />
             </RequireAuth>
  },
  {
    element: <RequireAuth>
              <MainLayout />
             </RequireAuth>,
    children: [
      {
        path: "/our-exercises/*",
        element: <OurExercises />,
      },
      {
        path: "/our-motion-tracking",
        element: <OurMotionTracking />,
      },
      {
        path: "/sample-workouts/*",
        element: <SampleWorkouts />,
      },
      {
        path: "/range-of-motion/*",
        element: <RangeOfMotion />,
      },
    ],
  },
  {
    path: "/login/*",
    element: <Login />
  },
  {
    path: "demo/*",
    element: <DemoExperience />
  }
];

const Router = () => {
  const element = useRoutes(routes);
  return element;
}

export default Router;
