import React, { Suspense } from 'react';
import Router from './routes';
import GlobalStyles from "../styles/globalStyles";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { themeDarkOptions } from '@kemtai/components/dist/MUI';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'libs/react-query';
import { CssBaseline } from "@mui/material";
import { Loader } from 'components';
import { initApp } from './initApp';
import { LocaleProvider } from 'libs/locale';
import { ConfirmationDialogProvider } from '@kemtai/ui';


initApp();

const App = () => {

  return (
    <QueryClientProvider client={queryClient}>
      <LocaleProvider>
        <ThemeProvider theme={createTheme(themeDarkOptions)}>
          <CssBaseline />
          <GlobalStyles />

          <Suspense fallback={<Loader />}>
            <ConfirmationDialogProvider>
              <Router />
            </ConfirmationDialogProvider>
          </Suspense>
        </ThemeProvider>
      </LocaleProvider>
    </QueryClientProvider>
  );

};

export default App;
